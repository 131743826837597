<template>
  <div class="mx-4">
    <div class="pt-2 ml-4 my-5">

      <div class="row text-muted text-black-50">
        <div class="col-lg-7  d-inline-flex ">
          <i class="fas fa-hand-holding-heart fa-3x mr-2"></i>
          <div>
            <h1 class="text-muted mb-1">Te invitamos a utilizar <b>eClock Go</b></h1>
            <h3 class="text-muted">Control de tiempo y asistencia de personal en la <b>nube</b>.</h3>

            <h2 class="text-muted mt-3">OJO: Solo para el dispositivo:</h2>
            <h1><b>{{noSerie}}</b></h1>
          </div>
        </div>

      </div>



    </div>

    <div class=" my-5 text-muted ">


      <div class="row ">
        <div class=" col-6 p-2  mb-3">

          <div class="card card-stats">

            <div class="card-header p-2 d-inline-flex">


              <i class="fas fa-mobile-alt text-muted fa-3x mr-3 mt-1"></i>
              <h2 class="mb-0 pb-0 text-muted">Escanea el siguiente código para continuar</h2>

            </div>
            <div class="card-body text-center">
              <vue-qr :text="URL" width="70%" logoSrc="img/e512transparente.png" qid="testid" :correctLevel="3" :size="2600"></vue-qr>
              <h5 class="mt-3 text-muted">O visita:</h5>
              <h3 class="mt-3 text-muted">{{URL}}</h3>



            </div>
            <div class="p-3 mt-4">
              <h3 class="text-muted mb-1">Invitado por:</h3>
              <div class="mx-3">
                <h4 class="text-muted mb-1">{{Suscripcion.etiqueta}}              </h4>
                <h5 class="text-muted mb-1">{{Suscripcion.telefono}}</h5>
                <h5 class="text-muted mb-1">{{Suscripcion.paginaWeb}}</h5>
              </div>


            </div>
          </div>
        </div>


        <div class=" col-6 p-2  mb-3">
          <div class="card card-stats">

            <div class="card-header p-2 mb-3 d-inline-flex">
              <i class="fas fa-question text-muted fa-3x mr-3 mt-1"></i>

              <h2 class="mb-0 pb-0 text-muted">¿Por qué eClock Go?</h2>



            </div>
            <div class="card-body ">
              <div class="ml-4">



                <div class=" mb-3">
                  <h3 class="info-title mb-1"><i class="fas fa-fingerprint mr-2"></i>Seguridad y biometría</h3>
                  <p class="description ml-4">
                    Tu información esta segura, además validamos la identidad de tu personal por diversos métodos <b>biométricos</b>.
                  </p>
                </div>

                <div class="info-area info-horizontal ">

                  <h3 class="info-title  mb-1"><i class="fas fa-globe-americas mr-2"></i>Solución en la nube</h3>
                  <p class="description  ml-4">
                    Administra la puntualidad de tu persona sin importar en que sucursal se encuentre.
                  </p>
                </div>
                <div class="info-area info-horizontal">

                  <h3 class="info-title mb-1"><i class="fas fa-sync mr-2"></i>En Tiempo Real</h3>
                  <p class="description ml-4">
                    Toda tu información al momento para ahorrarte tiempo que es dinero.
                  </p>

                </div>
                <div class="info-area info-horizontal">

                  <h3 class="info-title mb-1"><i class="far fa-paper-plane mr-2"></i>Notificaciones</h3>
                  <p class="description ml-4">
                    Notificaciones por faltas, retardos, permisos, etc, capturando su correo electronico y no. de celular.
                  </p>

                </div>
                <div class="info-area info-horizontal">

                  <h3 class="info-title mb-1"><i class="fas fa-tablet-alt mr-2"></i>Multi Plataforma</h3>
                  <p class="description ml-4">
                    Versión disponible para Windows, Mac, Linux, iPhone, iPad, Android, y mucho más.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="text-center">
        <h4 class="mt-5 text-muted">Agradecemos tu confianza.</h4>
        <h5 class="text-muted">Y recuerda estamos abiertos a tus comentarios.</h5>

      </div>

    </div>


    <div class="mt-5 text-center copyright text-wrap">
      eClock Go &copy; {{ year }}, creado con <i class="far fa-heart"></i> para mejorar la puntualidad en el mundo.
    </div>

  </div>
</template>
<script>
  import Servicio from '@/api-base/referencias.service';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';

  import VueQr from 'vue-qr'


  export default {
    name: 'Suscripcion-Impresion',
    props: {
      // linkID
      value: { type: String, default: '' },
      noSerie: { type: String, default: '' },
    },
    data() {
      return {
        year: new Date().getFullYear(),
        Seleccionado: null,

        Ayuda: 'En eClock creemos que el tiempo es dinero, por eso te compensamos por tus referencias, por ahora tienes dos opciones de ganar con nosotros:',
        info: null,
        cargando: true,
        error: ''
      }
    },
    components: {

      VueQr
    },
    filters: {


    },
    computed: {
      Suscripcion() {
        return SuscripcionesUsuarios.getSuscripcion();
      },
      URL() {

        if (this.value != '') {
          console.log(this.value);
          return Servicio.ObtenUrlLinkID(this.value);
        }
        var linkID = '';
        if (this.$route.query.linkID != null)
          linkID = this.$route.query.linkID;
        if (this.$route.params.linkID != null)
          linkID = this.$route.params.linkID;

        return Servicio.ObtenUrlLinkID(linkID);
      }
    },
    mounted() {

    },
    methods: {
      test(dataUrl, id) {
        console.log(dataUrl, id)
      }

    }
  }</script>



<style lang="scss" scoped>
  .info-title {
    color: #6c757d !important;
  }

  .description {
    color: #6c757d !important;
  }
</style>
