<template>
  <div>
    <div class="pt-2 pt-md-8">

      <div class="row">
        <div class="col-lg-7 col-md-9 d-inline-flex ">
          <i class="fas fa-dollar-sign fa-3x mr-2"></i>
          <div>
            <p>{{Ayuda}}</p>
            <small>*En tu contrato se encuentran los importes a recibir en cada caso así como los terminos y condiciones.</small>
          </div>
        </div>
        <div class="col-lg-5 col-md-3 ">
          <b-row align-h="end" class="m-2">
            <base-button icon
                         round :loading="cargando" @click="Actualiza">
              <i class="fas fa-sync"></i>
            </base-button>


          </b-row>
        </div>
      </div>



    </div>

    <div class=" mt-2">


      <div class="row ">
        <div class=" col-12 col-md-6 p-2  mb-3">
          <div class="card card-stats">

            <div class="card-header p-2">
              <router-link :to="{}" class="pull-right p-2" data-effect="fadeOut" @click.native="MostrarDispositivos=true">
                <i class="fas fa-plus"></i>
              </router-link>
              <div class="info-icon text-center icon-success  float-left mr-2  ">
                <i class="fas fa-fingerprint"></i>
              </div>

              <h3 class="mt-2 mb-0 pb-0 ">Dispositivos</h3>
              <collapse :animation-duration="200">
                <collapse-item title="Configura y Gana por cliente convertido." name="1">
                  <div>
                    Gana más por registrar los checadores que distribuyes, configúralos, imprimir el código de seguimiento del checador y colocarlo junto al producto, así le facilitaremos la vida al cliente y cuando el compre sabremos a quien agradecerle.
                  </div>
                </collapse-item>
              </collapse>

            </div>
            <div class="card-body  mb-3">
              <div ref="Cabecera"></div>
              <vue-funnel-graph v-if="infoDispositivos" :width="width" :height="height" :labels="DispositivosLabels"
                                :values="DispositivosValores" :colors="colors" :sub-labels="DispositivosSubLabels" :direction="direction"
                                :gradient-direction="gradientDirection"
                                :animated="true" :display-percentage="true"></vue-funnel-graph>
            </div>
          </div>
        </div>


        <div class=" col-12 col-md-6 p-2  mb-3">
          <div class="card card-stats">

            <div class="card-header p-2">
              <router-link :to="{}" class="pull-right p-2" data-effect="fadeOut" @click.native="MostrarSuscripcion=true">
                <i class="fas fa-share"></i>
              </router-link>
              <div class="info-icon text-center icon-warning  float-left mr-2  ">
                <i class="fas fa-bullhorn"></i>
              </div>
              <h3 class="mt-2 mb-0 pb-0 ">Referencia</h3>
              <collapse :animation-duration="200">
                <collapse-item title="Gana dinero por uso o contratación." name="1">
                  <div>
                    Gana dinero si tus referenciados utilizan eClock Go por un periodo de tiempo o si deciden contratar alguna versión de pago, para ello imprime el código de seguimiento de tu compañía o compárteles a tus prospectos tu liga, en todo momento podrás realizar un seguimiento.
                  </div>
                </collapse-item>
              </collapse>

            </div>
            <div class="card-body mb-3">
              <vue-funnel-graph v-if="infoReferencia" :width="width" :height="height" :labels="ReferenciaLabels"
                                :values="ReferenciaValores" :colors="colors" :sub-labels="ReferenciaSubLabels" :direction="direction"
                                :gradient-direction="gradientDirection"
                                :animated="true" :display-percentage="true"></vue-funnel-graph>
            </div>
          </div>

        </div>


      </div>


    </div>

    <DispositivosModal v-if="MostrarDispositivos" :Mostrar.sync="MostrarDispositivos" :Ayuda="Ayuda" v-model="Seleccionado"
                       v-on:guardado="Actualiza"></DispositivosModal>

    <SuscripcionModal v-if="MostrarSuscripcion" :Mostrar.sync="MostrarSuscripcion"></SuscripcionModal>



  </div>
</template>
<script>
  import Servicio from '@/api-base/referencias.service';
  import DispositivosModal from './Dispositivos.modal.vue';
  import SuscripcionModal from './Suscripcion.modal.vue';
  import swal from 'sweetalert2';
  import { Collapse, CollapseItem } from 'src/components';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  import { VueFunnelGraph } from 'vue-funnel-graph-js';


  export default {
    name: 'Embajadores-listado',
    data() {
      return {
        MostrarSuscripcion: false,
        MostrarNuevo: false,
        MostrarDispositivos: false,

        BaseGetsListado: {
          Borrados: false,
          PaginaNo: 0,
          PaginaLen: 50,
          filtro: '',
        },

        Seleccionado: null,

        Ayuda: 'En eClock creemos que el tiempo es dinero, por eso te compensamos por tus referencias, por ahora tienes dos opciones de ganar con nosotros:',
        info: null,
        infoReferencia: null,
        infoDispositivos: null,
        cargando: true,
        error: '',


        labels: ['Impressions', 'Add To Cart', 'Buy'],
        subLabels: ['Direct', 'Social Media', 'Ads'],
        values: [
          // with the given Labels and SubLabels here's what the values represent:
          //
          // Direct, Social, Ads
          //    |      |     |
          //    v      v     v
          [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
          [3000, 1700, 1000], // Segments of "Add To Cart"
          [600, 200, 130]   // Segments of "Buy"
        ],

        colors: [
          ['#FFB178', '#FF3C8E'], // color set for "Impressions" segment
          ['#A0BBFF', '#EC77FF'], // color set for "Add To Cart" segment
          ['#A0F9FF', '#7795FF']  // color set for "Buy" segment
        ],
        direction: 'horizontal',
        gradientDirection: 'horizontal',
        height: 300,
        width: 589//725,
      }
    },
    computed: {
      ReferenciaLabels() {
        if (this.infoReferencia == null || this.infoReferencia.listado.length < 1)
          return [];
        var Usuario = this.infoReferencia.listado[0];
        var Etiquetas = [];
        Usuario.datos.forEach(dato => {
          Etiquetas.push(dato.proceso);
        });
        return Etiquetas;
      },
      ReferenciaSubLabels() {
        if (this.infoReferencia == null || this.infoReferencia.listado.length < 1)
          return [];
        var Etiquetas = [];
        this.infoReferencia.listado.forEach(Usuario => {

          Etiquetas.push(Usuario.usuarioNombre);
        });
        return Etiquetas;
      },
      ReferenciaValores() {
        if (this.infoReferencia == null || this.infoReferencia.listado.length < 1)
          return [];
        var Valores = [];
        
        var UsuarioPos = 0;
        this.infoReferencia.listado.forEach((Usuario,UsuarioPos) => {
          
          Usuario.datos.forEach((dato, datoPos) => {
            if (UsuarioPos == 0) {
              var Fila = [];
              Fila.push(dato.cantidad);
              Valores.push(Fila);
            }
            else {
              Valores[datoPos].push(dato.cantidad);
            }
            //Valores.push(dato.cantidad);
          });
          
        });
        return Valores;
      },
      DispositivosLabels() {
        if (this.infoDispositivos == null || this.infoDispositivos.listado.length < 1)
          return [];
        var Usuario = this.infoDispositivos.listado[0];
        var Etiquetas = [];
        Usuario.datos.forEach(dato => {
          Etiquetas.push(dato.proceso);
        });
        return Etiquetas;
      },
      DispositivosSubLabels() {
        if (this.infoDispositivos == null || this.infoDispositivos.listado.length < 1)
          return [];
        var Etiquetas = [];
        this.infoDispositivos.listado.forEach(Usuario => {

          Etiquetas.push(Usuario.usuarioNombre);
        });
        return Etiquetas;
      },
      DispositivosValores() {
        if (this.infoDispositivos == null || this.infoDispositivos.listado.length < 1)
          return [];
        var Valores = [];

        var UsuarioPos = 0;
        this.infoDispositivos.listado.forEach((Usuario, UsuarioPos) => {

          Usuario.datos.forEach((dato, datoPos) => {
            if (UsuarioPos == 0) {
              var Fila = [];
              Fila.push(dato.cantidad);
              Valores.push(Fila);
            }
            else {
              Valores[datoPos].push(dato.cantidad);
            }
            //Valores.push(dato.cantidad);
          });

        });
        return Valores;
      }
    },
    components: {
      DispositivosModal,
      SuscripcionModal,
      Collapse, CollapseItem,
      VueFunnelGraph
    },
    filters: {
      Foto(Registro) {
        return Servicio.FotoURL(Registro);
      },

    },
    mounted() {
      this.width = this.$refs.Cabecera.clientWidth;
      this.Actualiza();
    },
    methods: {
      Borrar(seleccionado) {
        //console.debug(id);
        swal.fire({
          title: '¿Estas seguro?',
          text: `Estas por borrar una región (${seleccionado.etiqueta}).`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn  btn-danger ',
            cancelButton: 'btn '
          },
          confirmButtonText: 'Si, Borrarlo',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then((resultado) => {
          console.debug(resultado);
          if (resultado.isConfirmed)

            Servicio.delete(this, seleccionado.id, (response) => {
              //console.debug(response.data);
              swal.fire({
                title: 'Borrado',
                text: 'El registro se ha borrado satisfactoriamente.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-fill'
                },
                buttonsStyling: false
              });
              this.Actualiza();
            });

        });
      },
      Editar(seleccionado) {
        this.MostrarDispositivos = false;
        this.Seleccionado = seleccionado;

        this.MostrarDispositivos = true;
      },
      OnFiltrar(filtro) {
        //     this.BaseGetsListado.Filtro = filtro;
        this.Actualiza();
      },
      Actualiza() {
        //     console.debug(this.$appInsights);
        // this.$appInsights.loadAppInsights();
        /*   this.$appInsights.context.user.authenticatedId = `My authenticated Id`;
           this.$appInsights.context.user.accountId = `My Account Id`;
           this.$appInsights.context.user.localId = `My Local Id`;*/

        //   console.debug(this.$appInsights);
        this.error = '';
        var SuscripcionID = SuscripcionesUsuarios.getSuscripcionID();
        // this.cargando = true;
        Servicio.FunnelGets(this, {
          TipoReferencia: 'NuevaSuscripcion',
          SuscripcionID: SuscripcionID,
        }, (response) => {
          this.infoReferencia = response.data;
          console.debug(this.infoReferencia);
          //          this.$notify({ type: 'info', title: "Listado obtenido" });
        }, (MsgError, error) => {
          // console.debug(MsgError);
          this.error = MsgError;
          //this.$notify({ type: 'danger', title: MsgError });
        }, () => {
          this.cargando = false;

        });

        Servicio.FunnelGets(this, {
          TipoReferencia: 'NuevoDispositivo',
          SuscripcionID: SuscripcionID,
        }, (response) => {
          this.infoDispositivos = response.data;
            console.debug(this.infoDispositivos);
          //          this.$notify({ type: 'info', title: "Listado obtenido" });
        }, (MsgError, error) => {
          // console.debug(MsgError);
          this.error = MsgError;
          //this.$notify({ type: 'danger', title: MsgError });
        }, () => {
          this.cargando = false;

        });
      },

    }
  }</script>
<style lang="scss" scoped>



  @media (min-width: 35em) {
    .card-columns-listado {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 55em) {
    .card-columns-listado {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 80em) {
    .card-columns-listado {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      orphans: 1;
      widows: 1;
    }
  }

  @media (min-width: 97em) {
    .card-columns-listado {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
      orphans: 1;
      widows: 1;
    }
  }

  .card {
    display: inline-block; /*    // Don't let them vertically span multiple columns */
    width: 100%; /*    // Don't let their width change*/
  }
</style>
