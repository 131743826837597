<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">Registro de relojes checadores</h5>
      <div v-if="Paso=='Conecta'">
        <h3 class="card-title">Conectalos a la red y a la energía electrica.</h3>
        <span class="text-muted">Conectalos a eClock Go e imprime el código de seguimiento (QR), de esa forma sabremos que la venta te corresponde. <a href="https://support.eclock.com.mx/portal/es/kb/articles/configurar-reloj-checador-a-internet" target="_blank">¿Cómo configurar el reloj checador?</a></span>
      </div>

      <div v-if="Paso=='Captura'">
        <h3 class="card-title">Ingresa los números de serie.</h3>
        <div>
          <span class="text-muted" v-if="Escanear">Captura los numeros de serie de los dispositivos o escanea su código.</span>
          <span class="text-muted" v-else>Captura los numeros de serie de los dispositivos y configuralos para que se conecten a eClock. <a href="https://support.eclock.com.mx/portal/es/kb/articles/configurar-reloj-checador-a-internet" target="_blank">¿Cómo configurar el reloj checador?</a></span>
        </div>
      </div>


    </div>


    <template>
      <div v-if="Paso=='Conecta'" class="text-center">
        <h1>
          <i class="fas fa-network-wired mx-3"></i>
          <i class="fas fa-wifi mx-3"></i>
          <i class="fas fa-plug mx-3"></i>

        </h1>
        <hr class="my-md-4" />
        <base-button type="primary" @click="Paso='Captura'">
          Los he conectado
        </base-button>
        <a href="#" class="d-block mt-2" @click="Modal=false">
          <i class="fas fa-chevron-left"> </i> Cancelar
        </a>
      </div>


      <div v-if="Paso=='Captura'" class="text-center">
        <MazInput v-model="NoSerie" clearable :loading="cargando"
                  placeholder="No. de Serie">

          <a slot="icon-right" href="#" @click="Agregar">
            <i class="fas fa-plus"></i>
          </a>
        </MazInput>
        <ValidationObserver v-slot="{ handleSubmit}">
          <form @submit.prevent="handleSubmit(Guardar)">
            <p v-if="HayDispositivos && !PuedeAgregar" class="badge badge-info text-wrap">
              Conectando al dispositivo...
            </p>


            <div v-for="dispositivo in Dispositivos" v-bind:key="dispositivo.NoSerie" class="my-2">

              <MazInput v-model="dispositivo.NoSerie"
                        placeholder="No. Serie"
                        :loading="dispositivo.Desconectado || dispositivo.ReferenciaLinkID==''" disabled>

                <b-iconstack slot="icon-left" scale="1.45">
                  <b-icon stacked icon="cloud-fill" variant="info" scale="0.75"></b-icon>
                  <b-icon v-if="dispositivo.Desconectado" stacked icon="slash-circle" variant="danger"></b-icon>
                </b-iconstack>
                <div slot="icon-right">
                  <a class="mx-2 text-success" v-if="dispositivo.ReferenciaLinkID!=''" href="#" @click="Imprimir(dispositivo.RefID)">
                    <i class="fas fa-qrcode"></i>
                  </a>
                  <a href="#" class="mx-2" @click="Quitar(dispositivo)">
                    <i class="far fa-trash-alt"></i>
                  </a>
                </div>

              </MazInput>
              <DispositivosImpresion class="d-none" :ref="dispositivo.RefID" v-model="dispositivo.ReferenciaLinkID" :noSerie="dispositivo.NoSerie"/>
            </div>


            <div>

              <small v-if="Conectados==1">Un checador conectado</small>
              <small v-else>{{Conectados}} checadores conectados</small>
            </div>
            <qrcode-stream v-if="Escanear" @decode="onDecode" @init="onInit"></qrcode-stream>
            <p v-if="Erroneo" class="badge badge-danger text-wrap">
              {{ Erroneo }}
            </p>
            <hr class="my-md-4" />
            <!--  <base-button v-if="!cargando" native-type="submit" type="primary" :disabled="!PuedeAgregar">
              Agregar
            </base-button>-->
            <div v-if="cargando">
              <b-spinner type="grow" label="cargando..."></b-spinner>
              Cargando...
            </div>

            <a href="#" class="d-block " @click="Paso='Conecta'">
              <i class="fas fa-chevron-left"> </i> Regresar
            </a>
          </form>
        </ValidationObserver>
      </div>




    </template>

  </modal>
</template>


<script>

  import Servicio from '@/api-base/referencias.service';
  import Imprimir from '@/api-base/imprimir';
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  import DispositivosImpresion from './Dispositivos.impresion.vue';


  export default {
    name: 'Dispositivos-Referencia',
    data() {
      return {
        wizardModel: {},
        Modal: false,
        cargando: false,

        NoSeries: [],
        Erroneo: '',
        Escanear: false,
        errorMessage: '',
        Paso: 'Conecta',
        NoSerie: '',
        Dispositivos: [],
        AccesoPredeterminado: true,
        UbicacionID: '',
        refID: 1,
      };
    },

    components: {
      DispositivosImpresion
    },

    props: {
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },

      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },

    computed: {
      Suscripcion() {
        return SuscripcionesUsuarios.getSuscripcion();
      },
      FaltaEtiqueta() {
        return this.Dispositivos.filter(item => item.Etiqueta == null || item.Etiqueta == "").length;
      },
      HayDispositivos() {
        return this.Dispositivos.length > 0;
      },
      PuedeAgregar() {
        if (this.Dispositivos.length <= 0)
          return false;
        return this.Conectados == this.Dispositivos.length;
      },

      Conectados() {
        return this.Dispositivos.filter(item => !item.Desconectado).length;
      },

    },
    watch: {

      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;

    },

    sockets: {
      OnConexion(resultado) {
        if (this.Modal) {
          //Si se esta mostrando el componente
          console.debug(resultado);
          var E = this.Dispositivos.filter(item => item.NoSerie == resultado.noSerie);
          if (E.length > 0) {
            E[0].Desconectado = !resultado.conectado;
            if (resultado.conectado) {
              if (E[0].ReferenciaLinkID == '') {
                Servicio.patch(this,
                  {
                    suscripcionID: SuscripcionesUsuarios.getSuscripcionID(),
                    usuarioID: SuscripcionesUsuarios.SuscripcionesUsuario[0].usuarioID,
                    extra: E[0].NoSerie,
                    tipoReferencia:'NuevoDispositivo'
                  }, (response) => {
                    E[0].ReferenciaLinkID = response.data.linkID;
                    //this.info = response.data;
                    //this.compartir = Servicio.ObtenUrlLinkID(response.data.linkID);
                    //console.debug(response.data);
                  }, (MsgError) => {
                    E[0].Error = MsgError;
                  });
              }
            }
          }
        }
      },
    },

    methods: {

      Imprimir(RefID) {
        var sImpresion = this.$refs[RefID][0];
        console.debug(sImpresion);
        Imprimir.PrintElem(sImpresion.$el.innerHTML);
      },
      Quitar(Dispositivo) {
        const index = this.Dispositivos.indexOf(Dispositivo);
        if (index > -1) {
          this.Dispositivos.splice(index, 1);
        }
      },
      Agregar() {
        if (this.NoSerie != null && this.NoSerie != "") {
          var Nuevo = this.NoSerie.trim();
          if (Nuevo != "") {
            if (this.Dispositivos.filter(item => item.NoSerie == Nuevo).length <= 0) {
              this.Dispositivos.push({
                NoSerie: Nuevo,
                Etiqueta: '',
                placeholder: `Etiqueta (${this.NoSerie})`,
                Desconectado: true,
                ReferenciaLinkID: '',
                RefID: `sImpresion${this.refID++}`,
                Error: '',
              });
              this.$socket.invoke('OnConecta',
                Nuevo)
                .then(response => {
                  console.debug(response);
                  /*
                  //No hizo nada el siguiente código al momento
                  this.$socket.on('OnConecta', (data) => {
                    console.debug(data);
                  });*/
                });
            }
          }
        }
        this.NoSerie = "";
      },
      Valida() {

        this.Paso = 'Valida';

      },

    }
  };
</script>

<style scoped lang="css">
  .pull-right-checador {
    padding-right: 8px !important;
  }

  .pull-left-checador {
    padding-left: 8px !important;
  }
</style>

