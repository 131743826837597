/*import JQuery from 'jquery'
let $ = JQuery*/

export default {
  /*
  Url(laURL) {
    $('<iframe>', { name: 'myiframe', src: laURL, class: 'printFrame' })
      .appendTo('body')
      .contents();

    window.frames['myiframe'].focus();
    window.frames['myiframe'].print();

    setTimeout(() => { $(".printFrame").remove(); }, 5000);
  }
  */
  PrintElem(innerHTML) {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head>' +
      '<link href="nucleo-icons.css?" rel="stylesheet">' +
      '<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.2/css/all.css">'+
        '<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.2/css/v4-shims.css">'+
      '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">' +
      '<title>' + document.title + '</title>');
    mywindow.document.write('</head><body >');
    //mywindow.document.write('<h1>' + document.title + '</h1>');
    mywindow.document.write(innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(() => {
      mywindow.print();
      mywindow.close();
    }, 1000);
   /* 
    -*/
    return true;
  }
}
