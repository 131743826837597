<template>
  <modal :show.sync="Modal"
         class="modal-black" centered
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">{{Categoria}}</h5>
      <h3 class="card-title">{{Titulo}}</h3>
      <span class="text-muted">{{Ayuda}}</span>
    </div>

    <template class="text-center">


      <div class="text-center">
        <a v-if="!cargando" :href="compartir" v-clipboard:copy="compartir" target="_blank"
           v-clipboard:success="onCopy">
          {{compartir}}
        </a>
        <base-button v-if="!cargando" v-clipboard:copy="compartir"
                     v-clipboard:success="onCopy" block>
          <i class="fa  fa-clipboard mr-2" aria-hidden="true"></i>Copiar al portapapeles
        </base-button>
        <base-button v-if="!cargando" type="primary" block @click="OnImprimir">
          <i class="fas fa-print mr-2"></i> Imprimir QR
        </base-button>



        <hr class="my-md-4" />


        <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
          {{ Erroneo }}
        </p>
        <div v-if="cargando" class="text-center mt-7">
          <b-spinner type="grow" label="cargando..."></b-spinner>
          Cargando...
        </div>
        <router-link :to="{}" class="text-muted  d-block mt-2" data-effect="fadeOut" @click.native="Cerrar()">
          <i class="fas fa-chevron-left"> </i> Cancelar
        </router-link>

        <!--<iframe class="d-none" v-if="imprimiendo" id="iFrameImpresion" ref="iFrameImpresion" name="iFrameImpresion" :src="Src" />-->
        <!--Se deja visible para cargar los iconos, de lo contrario no se muestran-->
        <!--<iframe v-if="info" style="height:1px" class="m-0 p-0" width="1" id="iFrameImpresion" ref="iFrameImpresion" name="iFrameImpresion" :src="Src" />-->
        <!--Imprimo mejor un componente y evito la carga de toda la página-->
        <SuscripcionImpresion class="d-none" ref="sImpresion" v-model="linkID"/>
      </div>

      </template>

    </modal>
  </template>

  <script>
    import Servicio from '@/api-base/referencias.service';
    import Imprimir from '@/api-base/imprimir';
    import Vue from 'vue'
    import VueClipboard from 'vue-clipboard2'
    Vue.use(VueClipboard)
    import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
    import SuscripcionImpresion from './Suscripcion.impresion.vue';

    export default {
      name: 'Referencias-Suscripcion',
      props: {
        Mostrar: {
          type: Boolean,
          default: false,
          description: "Indica si se mostrará"
        },
        Categoria: { type: String, default: 'Referencia' },
        Titulo: { type: String, default: 'Compartir' },
        Ayuda: { type: String, default: 'Invita a otros a usar eClock Go imprimiendo tu QR y colocandolo en la caja del checador o compartiendo tu liga y explicarles los beneficios.' },

        //Muestra que esta cargando
        loading: { type: Boolean, default: false },
      },
      components: {

        SuscripcionImpresion
      },
      data() {
        return {
          compartir:'',
          info: null,
          Modal: false,
          cargando: false,
          imprimiendo:false,
          Erroneo: ''
        }
      },
      computed: {
        linkID() {
          if (this.info)
            return this.info.linkID;
          return '';
        },
        Src() {
          return `#/embajadores/suscripcion/${this.linkID}`;
        }
      },

      watch: {

        Mostrar(Mostrar) {

          this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
          if (Mostrar) {
            this.Modal = true;
          }
          else
            this.Modal = false;
        },
        Modal(Modal) {
          if (!Modal)
            this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
        }
      },
      mounted() {
        this.Modal = this.Mostrar;
        
        Servicio.patch(this,
          {
            suscripcionID: SuscripcionesUsuarios.getSuscripcionID(),
            usuarioID: SuscripcionesUsuarios.SuscripcionesUsuario[0].usuarioID,
            tipoReferencia: 'NuevaSuscripcion'
          }, (response) => {
            this.info = response.data;
            this.compartir = Servicio.ObtenUrlLinkID(this.info.linkID);
            //console.debug(response.data);
          }, (MsgError) => { this.Erroneo = MsgError; });
      },
      created() {
      },
      methods: {
        OnImprimir() {
          var sImpresion = this.$refs.sImpresion;
          console.debug(sImpresion);

          Imprimir.PrintElem(sImpresion.$el.innerHTML);
          /*
          this.imprimiendo = true;
          this.cargando = true;
          setTimeout(() => {
          //  this.$refs.iFrameImpresion.focus();
            //this.$refs.iFrameImpresion.print();
            window.frames["iFrameImpresion"].focus();
            window.frames["iFrameImpresion"].print();
            setTimeout(() => {
              this.cargando = false;
              this.imprimiendo = false;
            }, 1000);

          }, 1000);
          */

          //Imprimir.Url(`suscripcion/${this.info.linkID}`);
        },
        onCopy() {
          this.$notify({
            type: 'success',
            title: 'Se ha copiado al portapapeles'
          })
        },
        nuevo() {
          Servicio.post(this, this.datos, (response) => {
            this.$emit('guardado', response.data);

            this.Cerrar();

          }, (MsgError, error) => {
            this.Erroneo = MsgError;
          }, () => {
          });
        },
        Cerrar() {
          this.datos = {
            etiqueta: '', descripcion: ''
          };
          this.Modal = false;
        },
      }
    }
  </script>

  <style scoped>
  </style>
